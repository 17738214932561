import { SystemInfo } from "@taxy/common";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { size } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import AccessRules from "../AccessRules";
import Organisation from "../components/admin/organisations/Organisation";
import Organisations from "../components/admin/organisations/Organisations";
import AdminUsers from "../components/admin/users/AdminUsers";
import AuthActionHandlerPage from "../components/auth/AuthActionHandlerPage";
import LoginPage from "../components/auth/LoginPage";
import LogoutPage from "../components/auth/LogoutPage";
import RegisterPage from "../components/auth/RegisterPage";
import ResetPasswordPage from "../components/auth/ResetPasswordPage";
import Catalogue from "../components/catalogue/Catalogue";
import Catalogues from "../components/catalogue/Catalogues";
import CatalogueTeamSelect from "../components/catalogue/CatalogueTeamSelect";
import Client from "../components/client/Client";
import ManageClient from "../components/client/manage/ManageClient";
import Home from "../components/home/Home";
import ManageTeam from "../components/team/ManageTeam";
import ManageTeams from "../components/team/ManageTeams";
import WorkList from "../components/worklist/WorkList";
import taxy from "../config/taxy.json";
import DebugPage from "../DebugPage";
import DragAndDropPage from "../DragAndDropPage";
import { useApplicationState } from "../hooks/ApplicationState";
import useFirestoreService from "../hooks/FirestoreService";
import NotFoundPage from "../NotFoundPage";
import TermsAndConditionsPage from "../TermsAndConditionsPage";
import User from "../User";
import AdminDashboard from "./../components/admin/AdminDashboard";

const ApplicationRoutes = () => {
  const {
    loggedIn,
    isAdmin,
    isAccountant,
    isOrganisationAdmin,
    setShowRefreshMessage,
    isClient,
    adminOrganisationIds,
    organisationIds,
    clientIds,
    isDev,
  } = useApplicationState();
  const { pathname } = useLocation();
  const firestoreService = useFirestoreService();

  // indicates that the auth state is still loading (ie: we don't yet know if the user is logged in or not)
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    return onAuthStateChanged(getAuth(), () => {
      setLoading(false);
    });
  }, []);

  const checkSystemInfo = useCallback(async () => {
    const documentSnapshot = await firestoreService.getDocumentSnapshot(firestoreService.getSystemInfoDocument());
    const { buildNumber } = documentSnapshot.data() as SystemInfo;
    setShowRefreshMessage(!isDev && taxy.buildNumber !== buildNumber);
  }, [isDev, firestoreService, setShowRefreshMessage]);

  useEffect(() => {
    return firestoreService.watchDocument(firestoreService.getSystemInfoDocument(), () => {
      void checkSystemInfo();
    });
  }, [firestoreService, checkSystemInfo]);

  const isAdminOfMultipleOrgs = useMemo(() => size(adminOrganisationIds) > 1, [adminOrganisationIds]);

  const isAssignedToSingleClient = useMemo(
    () => isClient && size(organisationIds) === 1 && size(clientIds) === 1,
    [isClient, organisationIds, clientIds]
  );

  return loading ? null : (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/auth" element={<AuthActionHandlerPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/not-found" element={<NotFoundPage />} />

        {/* LOGGED IN ROUTES */}
        <Route path="/" element={loggedIn ? <Outlet /> : <Navigate to="/login" state={{ redirectTo: pathname }} />}>
          <Route
            index
            element={
              isAccountant || isClient ? (
                isAssignedToSingleClient ? (
                  <Navigate to={`/client/${organisationIds[0]}/${clientIds[0]}`} />
                ) : (
                  <Home />
                )
              ) : (
                <Navigate to={"/admin"} />
              )
            }
          />
          <Route path="/user" element={<User />} />
          <Route path="/client/:organisationId/:clientId" element={<Client />} />
          <Route path="/worklist" element={<WorkList />} />
          <Route path="/debug" element={<DebugPage />} />

          {/* DEV ROUTES */}
          <Route path="/" element={isDev ? <Outlet /> : <Navigate to="/not-found" />}>
            <Route path="/access" element={<AccessRules />} />
            <Route path="/dnd" element={<DragAndDropPage />} />
          </Route>

          {/*/ ADMIN ROUTES /*/}
          <Route path="/admin" element={isAdmin ? <Outlet /> : <Navigate to="/not-found" />}>
            <Route index element={<AdminDashboard />} />
            <Route path="/admin/organisations" element={<Organisations />} />
            <Route path="/admin/organisations/:organisationId" element={<Organisation />} />
            <Route path="/admin/users" element={<AdminUsers />} />
          </Route>

          {/* ACCOUNTANT ROUTES */}
          <Route path="/" element={isAccountant ? <Outlet /> : <Navigate to="/not-found" />}>
            <Route path="/client/:organisationId/:clientId/manage" element={<ManageClient />} />
          </Route>

          {/* ORGANISATION ADMIN ROUTES */}
          <Route path="/" element={isOrganisationAdmin ? <Outlet /> : <Navigate to="/not-found" />}>
            <Route
              path="/catalogues"
              element={
                isAdminOfMultipleOrgs ? (
                  <CatalogueTeamSelect />
                ) : (
                  <Navigate to={`/catalogues/${adminOrganisationIds[0]}`} />
                )
              }
            />
            <Route path="/catalogues/:organisationId" element={<Catalogues />} />
            <Route path="/catalogues/:organisationId/:catalogueId" element={<Catalogue />} />
            <Route
              path="/manage"
              element={isAdminOfMultipleOrgs ? <ManageTeams /> : <Navigate to={`/manage/${adminOrganisationIds[0]}`} />}
            />
            <Route path="/manage/:organisationId" element={<ManageTeam />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="not-found" />} />
      </Route>
    </Routes>
  );
};

export default ApplicationRoutes;
