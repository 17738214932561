import { FieldValue, Timestamp } from "firebase/firestore";
import { EntityEnum } from "../enums";
import { ClientStatusEnum } from "../enums/ClientStatus";
import { RequirementStatusEnum } from "../enums/RequirementStatus";
import { FirestoreDocument } from "../hooks/FirestoreService";

export type InviteType = {
  id: string;
  organisationId?: string;
  clientId?: string;
  isAdmin?: boolean;
  inviter: {
    email: string;
    firstName: string;
    lastName: string;
  };
  invitee: {
    email: string;
    firstName: string;
    lastName: string;
  };
  status: "PENDING" | "ACCEPTED" | "EXPIRED";
  createdTimestamp: Timestamp;
};

export type ClientId = {
  organisationId: string;
  clientId: string;
};

export type PeriodId = ClientId & {
  periodId: string;
};

export type EntityId = PeriodId & {
  entityId: string;
};

export type CategoryId = EntityId & {
  categoryId: string;
  subCategoryId?: string;
};

export type RequirementId = CategoryId & {
  requirementId: string;
};

// make sure we keep this helper
export type Writeable<T> = {
  [P in keyof T]: T[P] | FieldValue;
};

export interface FileMetadataType {
  contentType: string;
  customMetadata: {
    name: string;
    size: string;
  };
}

export interface FileType extends FirestoreDocument {
  fileId: string;
  name: string;
  dateUploaded: Timestamp;
  storageLocation: string;
  previewLocation?: string;
  downloadURL?: string;
  previewURL?: string;
  isPermanent: boolean;
  metadata?: FileMetadataType;
}

export type RequirementStatusType = "WITH_CLIENT" | "WITH_ACCOUNTANT" | "COMPLETE";

export type CommentType = {
  comment: string;
  createdAtTimestamp: number;
  createdBy: string;
  id: string;
  edited: boolean;
};

export interface RequirementType extends FirestoreDocument {
  requirementId: string;
  clientId: string;
  periodId: string;
  createdTimestamp: Timestamp;
  modifiedTimestamp: Timestamp;
  order: number;
  name: string;
  status: RequirementStatusType;
  accountantNotes?: string;
  fileIds: string[];
  comments?: CommentType[];
}

export type RequirementCatalogueType = Partial<RequirementType> &
  Pick<RequirementType, "requirementId" | "createdTimestamp" | "modifiedTimestamp" | "order" | "name">;

export interface CategoryType {
  id: string;
  categoryId: string;
  createdTimestamp: Timestamp;
  modifiedTimestamp?: Timestamp;
  order: number;
  name: string;
  requirements: RequirementType[];
  categories: CategoryType[];
}

export type CategoryCatalogueType = Omit<CategoryType, "requirements" | "categories"> & {
  requirements: Record<string, RequirementCatalogueType>;
  categories: Record<string, CategoryCatalogueType>;
};

export const isCategoryType = (obj: any): obj is CategoryCatalogueType => {
  return "categoryId" in obj;
};

export type RequirementCountsType = Record<RequirementStatusEnum, number>;

export interface EntityType {
  entityId: string;
  createdTimestamp: Timestamp;
  name: string;
  dueDate?: Timestamp;
  entityType: EntityEnum;
  requirementCounts?: RequirementCountsType;
}

export const isEntityType = (obj: any): obj is EntityType => {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  return "entityId" in obj;
};

export interface PeriodType extends FirestoreDocument {
  createdTimestamp: Timestamp;
  name: string;
  periodId: string;
}

export type RequirementCounts = Record<RequirementStatusEnum, number>;

export interface ClientType extends FirestoreDocument {
  clientId: string;
  organisationId: string;
  name: string;
  createdTimestamp: Timestamp;
  modifiedTimestamp: Timestamp;
  shoeboxEmail?: string;
  activePeriodId?: string;
  activePeriodName?: string;
  nextDueDate?: Timestamp;
  status?: ClientStatusEnum;
  requirementCounts?: RequirementCounts;
  isUpdated?: boolean;
  manager?: string;
}

export enum DragType {
  DRAGGABLE,
  SORTABLE,
}

export interface ShoeboxItem extends FirestoreDocument {
  from: string;
  to: string;
  subject?: string;
  text?: string;
  html?: string;
  files: Record<string, string>;
  status: "PENDING" | "ARCHIVED";
  createdTimestamp: Timestamp;
  modifiedTimestamp: Timestamp;
}

export interface CatalogueType {
  catalogueId: EntityEnum;
  createdTimestamp: Timestamp;
  name: string;
  categories: Record<string, CategoryCatalogueType>;
}

export const isCatalogueType = (obj: any): obj is CatalogueType => {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  return "catalogueId" in obj;
};

export interface UserType {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  organisationIds: string[];
  adminOrganisationIds?: string[];
  clientIds?: string[];
}

export interface OrganisationType extends FirestoreDocument {
  organisationId: string;
  name: string;
}

export interface FirebaseFunctionResultType {
  success: boolean;
  error?: string;
  ex?: unknown;
}
