import AddModeratorIcon from "@mui/icons-material/AddModerator";
import { Button, TableSortLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { includes, orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useAdminService from "../../../hooks/AdminService";
import { useApplicationState } from "../../../hooks/ApplicationState";
import { UserType } from "../../../types";
import AdminLayout from "../AdminLayout";
import UsersSearch from "../organisations/UserSearch";
import AddAdminUserDialog from "./AddAdminUserDialog";

type SortBy = "firstName" | "lastName" | "email";
type SortDirection = "asc" | "desc";

const AdminUsers = () => {
  const adminService = useAdminService();
  const { setSystemMessage } = useApplicationState();

  const [adminUsers, setAdminUsers] = useState<UserType[]>([]);

  const [sortBy, setSortBy] = useState<SortBy>("firstName");
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  // admin users after applying search filters
  const [filteredAdminUsers, setFilteredAdminUsers] = useState<UserType[]>([]);

  // admin users after applying sort filters
  const [sortedAdminUsers, setSortedAdminUsers] = useState<UserType[]>([]);

  // Admin dialogs
  const [addAdminUserDialogOpen, setAddAdminUserDialogOpen] = useState<boolean>(false);

  const listAdmins = useCallback(async () => {
    const results = await adminService.listAdminUsers();
    setAdminUsers(results);
  }, [adminService]);

  useEffect(() => {
    void listAdmins();
  }, [listAdmins]);

  const handleAddAdminUserDialogOpen = () => {
    setAddAdminUserDialogOpen(true);
  };

  const handleAddAdminUserDialogClose = () => {
    setAddAdminUserDialogOpen(false);
  };

  const handleAdminUserAdded = async (message?: string) => {
    setSystemMessage(message);
    handleAddAdminUserDialogClose();
    await listAdmins();
  };

  useEffect(() => {
    if (sortBy && includes(["firstName", "lastName", "email"], sortBy)) {
      setSortedAdminUsers(orderBy(filteredAdminUsers, [sortBy], [sortDirection]));
    } else {
      setSortedAdminUsers(filteredAdminUsers);
    }
  }, [sortBy, sortDirection, filteredAdminUsers]);

  const sortTable = (fieldName: SortBy) => {
    if (sortBy === fieldName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(fieldName);
      setSortDirection("asc");
    }
  };

  const handleSearchResults = (results: UserType[]) => {
    setFilteredAdminUsers(results);
  };

  const SortLabel = ({ field, label }: { field: SortBy; label: string }) => {
    return (
      <TableSortLabel active={sortBy === field} direction={sortDirection} onClick={() => sortTable(field)}>
        {label}
      </TableSortLabel>
    );
  };

  return (
    <AdminLayout
      header={
        <>
          <Typography variant="h4">Admin users</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddModeratorIcon />}
            onClick={handleAddAdminUserDialogOpen}
          >
            Add admin user
          </Button>
        </>
      }
    >
      <Grid container>
        <Grid item xs={12} sx={{ my: 2 }}>
          <UsersSearch users={adminUsers} onSearchResults={handleSearchResults} />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper} square elevation={0}>
            <Table aria-label="Users table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SortLabel field="firstName" label="First name" />
                  </TableCell>
                  <TableCell>
                    <SortLabel field="lastName" label="Last name" />
                  </TableCell>
                  <TableCell>
                    <SortLabel field="email" label="Email" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAdminUsers.map((admin) => (
                  <TableRow key={admin.uid}>
                    <TableCell>{admin.firstName}</TableCell>
                    <TableCell>{admin.lastName}</TableCell>
                    <TableCell>{admin.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <AddAdminUserDialog
        onSuccess={handleAdminUserAdded}
        onClose={handleAddAdminUserDialogClose}
        open={addAdminUserDialogOpen}
      />
    </AdminLayout>
  );
};

export default AdminUsers;
