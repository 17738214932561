import { Add as AddIcon } from "@mui/icons-material";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { includes, orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAdminService from "../../../hooks/AdminService";
import { OrganisationType } from "../../../types";
import AdminLayout from "../AdminLayout";
import AddOrganisationDialog from "./AddOrganisationDialog";
import OrganisationSearch from "./OrganisationSearch";

type SortBy = "name";
type SortDirection = "asc" | "desc";

const Organisations = () => {
  const navigate = useNavigate();
  const adminService = useAdminService();

  const [sortBy, setSortBy] = useState<SortBy>();
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const [organisations, setOrganisations] = useState<OrganisationType[]>([]);

  // organisation after applying sort filters
  const [sortedOrganisations, setSortedOrganisations] = useState<OrganisationType[]>([]);

  // organisation after applying search filters
  const [filteredOrganisations, setFilteredOrganisations] = useState<OrganisationType[]>([]);

  const [addOrganisationDialogOpen, setAddOrganisationDialogOpen] = useState<boolean>(false);

  const addOrganisation = () => {
    setAddOrganisationDialogOpen(true);
  };

  const addOrganisationClose = () => {
    setAddOrganisationDialogOpen(false);
  };

  const handleOrganisationAdded = async () => {
    await loadOrganisations();
    addOrganisationClose();
  };

  const handleSearchResults = (results: OrganisationType[]) => {
    setFilteredOrganisations(results);
  };

  const viewOrganisation = async (organisation: OrganisationType) => {
    navigate(`/admin/organisations/${organisation.organisationId}`);
  };

  const loadOrganisations = useCallback(async () => {
    const results = await adminService.listOrganisations();
    setOrganisations(results);
  }, [adminService]);

  useEffect(() => {
    void loadOrganisations();
  }, [loadOrganisations]);

  const sortTable = (fieldName: SortBy) => {
    setSortBy(fieldName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const SortLabel = ({ field, label }: { field: SortBy; label: string }) => {
    return (
      <TableSortLabel active={sortBy === field} direction={sortDirection} onClick={() => sortTable(field)}>
        {label}
      </TableSortLabel>
    );
  };

  useEffect(() => {
    if (sortBy && includes(["name"], sortBy)) {
      setSortedOrganisations(orderBy(filteredOrganisations, (client) => client[sortBy], sortDirection));
    } else {
      setSortedOrganisations(filteredOrganisations);
    }
  }, [sortBy, sortDirection, filteredOrganisations]);

  return (
    <AdminLayout
      header={
        <>
          <Typography variant="h4">Organisations</Typography>
          <Button variant="contained" startIcon={<AddIcon />} size="small" onClick={addOrganisation}>
            New Organisation
          </Button>
        </>
      }
    >
      <>
        <Grid item xs={12} sx={{ my: 2 }}>
          <OrganisationSearch organisations={organisations} onSearchResults={handleSearchResults} />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper} square elevation={0}>
            <Table aria-label="Organisation" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SortLabel field="name" label="Organisation" />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedOrganisations.map((organisation) => (
                  <TableRow
                    key={organisation.organisationId}
                    onClick={() => viewOrganisation(organisation)}
                    hover
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{organisation.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <AddOrganisationDialog
          open={addOrganisationDialogOpen}
          onClose={addOrganisationClose}
          onSuccess={handleOrganisationAdded}
        />
      </>
    </AdminLayout>
  );
};

export default Organisations;
