import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { OrganisationType } from "../../../types";
import { chain, debounce, includes, toLower } from "lodash";

interface OrganisationSearchProps {
  organisations: OrganisationType[];
  onSearchResults: (results: OrganisationType[]) => void;
}

const OrganisationSearch = ({ onSearchResults, organisations }: OrganisationSearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<OrganisationType[]>();

  // manage the value of the search field separately from the actual search term to allow for debounce
  const [searchFieldValue, setSearchFieldValue] = useState<string>("");

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchFieldValue(event.target.value);
  };

  const doSearch = useCallback((organisations: OrganisationType[], name: string) => {
    const searchResults = chain(organisations)
      .filter((organisation) => (name ? includes(toLower(organisation.name), toLower(name)) : true))
      .orderBy((client) => client.name)
      .value();
    setSearchResults(searchResults);
  }, []);

  const handleClear = () => {
    setSearchFieldValue("");
    setSearchTerm("");
  };

  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
      }, 150),
    []
  );

  useEffect(() => {
    debouncedSetSearchTerm(searchFieldValue);
  }, [searchFieldValue, debouncedSetSearchTerm]);

  useEffect(() => {
    doSearch(organisations, searchTerm);
  }, [searchTerm, organisations, doSearch]);

  useEffect(() => {
    if (searchResults) {
      onSearchResults(searchResults);
    }
  }, [searchResults, onSearchResults]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* SEARCH FIELD */}
      <TextField
        sx={{ flexGrow: 1 }}
        value={searchFieldValue}
        autoFocus
        placeholder="Search"
        size="small"
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} size="small">
                <CloseIcon sx={{ color: "primary.main" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default OrganisationSearch;
