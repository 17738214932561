import BusinessIcon from "@mui/icons-material/Business";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Grid, Typography } from "@mui/material";
import AdminLayout from "./AdminLayout";
import AdminNavigationCard from "./AdminNavigationCard";

const AdminDashboard = () => {
  return (
    <AdminLayout header={<Typography variant="h4">Administration</Typography>}>
      <Grid container gap={2}>
        <AdminNavigationCard icon={BusinessIcon} title="Organisations" url="/admin/organisations" />
        <AdminNavigationCard icon={SupervisorAccountIcon} title="Admin users" url="/admin/users" />
      </Grid>
    </AdminLayout>
  );
};

export default AdminDashboard;
